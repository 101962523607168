:root {
  --app-font: "Quicksand", "Lato", sans-serif;
  --neutral-color: white;
}

.App {
  font-family: var(--app-font);
  text-align: center;
  background-color: #1e1d1f;
  user-select: none;
  min-height: 100vh;
}

.titleHeader {
  color: white;
  text-align: center;
  margin-bottom: 10px;
}

.radioContainer {
  background-color: #1e1d1f;
  padding-top: 25px;
  padding-bottom: 10px;
}
.radioText {
  color: white;
  font-weight: 800;
  margin-left: 5px;
}

.nameFieldContainer {
  margin-top: 50px;
}
.nameFieldCol {
  border-radius: 20px;
  background-color: #151515 !important;
  padding: 15px 30px 15px 30px;
  margin: 30px 15px 0px 15px;
  max-width: 46%;
  min-width: 310px;
  text-align: center;
}
.nameFieldHeader {
  color: white;
  text-align: center;
}
.nameField {
  margin-top: 5px;
  width: 85%;
}
.addButton {
  margin-top: 10px;
  margin-left: 5px;
}
.removeButton {
  margin-left: 10px;
}

.invisibleButton {
  background: none;
  border: none;
  padding: 0;
  outline: 0;
}
.invisibleButton:hover {
  outline: 0;
}
.invisibleButton:active {
  outline: 0;
  box-shadow: none;
}
.invisibleButton:focus {
  outline: 0;
  box-shadow: none;
}

.modalDialog {
  margin-top: 12%;
  border: 7px #3C6369 solid !important;
  border-radius: 10px !important;
}
.pickedNameBody {
  background-color: #719AA0;
  color: white;
  font-weight: 600;
}
.nameTitle {
  font-size: 50px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.keepNameButton {
  color: #3C6369;
  background-color: white;
  border: 1px #3C6369 solid;
  min-width: 140px;
  margin-right: 30px;
  font-size: 14px;
  text-transform: uppercase;
}
.removeNameButton {
  color: white;
  background-color: #3C6369;
  border: 1px white solid;
  min-width: 140px;
  font-size: 14px;
  text-transform: uppercase;
}
.keepNameButton:hover,
.keepNameButton:active,
.keepNameButton:focus,
.removeNameButton:hover,
.removeNameButton:active,
.removeNameButton:focus {
  color: white;
  background-color: #213639;
  border: 1px black solid;
  min-width: 140px;
  font-size: 14px;
  text-transform: uppercase;
}